<template>
  <div>
    <div class="d-flex flex-row align-items-center">
      <v-select
        v-model="showDoctorsSplits"
        :options="doctorsSplits"
        :multiple="true"
        placeholder="All doctors"
        class="w-100 me-4"
      />
      <button
        type="button"
        class="btn btn-primary d-flex flex-row align-items-center pe-8"
        @click="openExportCalendar"
      >
        <i class="bi-link-45deg fs-5"></i>Export
      </button>
      <ExportCalendar
        name="export-calendar-modal"
        @cancel="closeExportCalendarModal"
        v-model="isExportCalendarModalOpen"
      >
      </ExportCalendar>
    </div>

    <vue-cal
      v-if="eventsItems"
      ref="calendar"
      class="mt-4"
      style="height: 75vh"
      :time-from="8 * 60"
      :time-to="18 * 60"
      :disable-views="['years', 'year']"
      :timeCellHeight="80"
      active-view="week"
      :split-days="showDoctorsSplits"
      :events="eventsItems"
      :on-event-click="onEventClick"
      :selected-date="selectedDate"
      cell-contextmenu
      today-button
      @cell-dblclick="createEvent"
      :drag-to-create-threshold="5"
      :dblclick-to-navigate="false"
    />
    <!-- @view-change="viewChange"
      @cell-contextmenu="cellContextMenu" -->
    <!-- editable-events
    :snap-to-time="5" -->
    <NewOperation
      name="new-operation-modal"
      v-model="isNewOperationModalOpen"
      @cancel="closeNewOperationModal"
      @confirm="onOperationCreated"
    >
      <template v-slot:title>New event</template>
    </NewOperation>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import ApiService from "@/core/services/ApiService";
import EventType from "@/core/constants/EventType";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewOperation from "@/components/modals/operations/NewOperation.vue";
import ExportCalendar from "@/components/modals/calendar/ExportCalendar.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "Calendar",
  components: {
    VueCal,
    vSelect,
    NewOperation,
    ExportCalendar,
  },
  data() {
    return {
      isLoading: false,
      selectedDate: null,
      eventsItems: [],
      showDoctorsSplits: [],
      doctorsSplits: [],
      totalEvents: 0,
      isNewOperationModalOpen: false,
      isExportCalendarModalOpen: false,
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("Operations Calendar", []);
    this.fetchItemsFromApi();
    if (this.$route.query.date) {
      this.selectedDate = new Date(this.$route.query.date);
    }
  },
  methods: {
    openExportCalendar() {
      this.$vfm.show("export-calendar-modal");
    },
    closeExportCalendarModal() {
      this.isExportCalendarModalOpen = false;
    },
    openNewOperation(customOperation) {
      this.$vfm.show("new-operation-modal", customOperation);
    },
    closeNewOperationModal() {
      this.isNewOperationModalOpen = false;
    },
    onOperationCreated(createdOperation) {
      this.$vfm.hide("new-operation-modal");
      this.fetchItemsFromApi();
      this.selectedDate = new Date(createdOperation.start);
    },
    createEvent(e) {
      if (e instanceof Date) {
        e.setHours(e.getHours(), 0, 0);
        this.openNewOperation({
          start: e,
          type: "CONSULTATION",
        });
      } else {
        e.date.setHours(e.date.getHours(), 0, 0);
        const doctor = this.doctorsSplits.find((split) => split.id == e.split);
        this.openNewOperation({
          start: e.date,
          doctor: { id: doctor.doctorId },
          type: "CONSULTATION",
        });
      }
    },
    // cellContextMenu(e) {
    //   console.log(e);
    // },
    viewChange(e) {
      // console.log(e);
      // {view: 'week', startDate: Mon Dec 13 2021 00:00:00 GMT+0100 (Central European Standard Time), endDate: Sun Dec 19 2021 23:59:59 GMT+0100 (Central European Standard Time), events: Array(0), week: 50}
      // Refresh events in that date range (startDate - endDate)
    },
    onEventClick(event, e) {
      this.$router.push({ name: "operation", params: { id: event.id } });

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isLoading = true;

      // const tableSearch = {};
      // console.log(this.serverParams.columnFilters);
      // Object.keys(this.serverParams.columnFilters).forEach((e) => {
      //   if (!this.serverParams.columnFilters[e]) return;
      //   tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
      // });

      const getApiParams = {
        // ...tableSearch,
        // limit: this.serverParams.perPage,
        // page: this.serverParams.page,
        // sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "doctor patient products",
        limit: 10000000,
      };

      ApiService.query("/operations", { params: getApiParams })
        .then((res) => {
          console.log(res);
          this.eventsItems = res.data.results.map((event) => {
            // Event title
            try {
              event.title = `<strong>${event.patient.firstname} ${
                event.patient.lastname
              }</strong><br>${EventType[event.type].label}`;
            } catch (err) {
              event.title = `<strong>${event.type}</strong>`;
            }

            // Event description
            try {
              event.content = `Dr. ${event.doctor.firstname} ${event.doctor.lastname}`;
            } catch (err) {
              event.content = "";
            }

            // Event timeframe
            event.start = new Date(event.start);
            const minutes = event.duration.hours * 60 + event.duration.minutes;
            event.end = new Date(event.start.getTime() + minutes * 60000);

            // Split days per doctor
            try {
              const split = this.doctorsSplits.find(
                (e) => e.label === `Dr. ${event.doctor.lastname}`
              );
              if (split) {
                event.split = split.id;
              } else {
                event.split = this.doctorsSplits.length + 1;
                this.doctorsSplits.push({
                  id: event.split,
                  label: `Dr. ${event.doctor.lastname}`,
                  doctorId: event.doctor.id,
                });
              }
            } catch (err) {
              console.warn(err);
            }

            event.class = EventType[event.type].colorClass;
            if (event.downPayment) {
              event.class += " down-paid";
            }
            return event;
          });

          this.totalEvents = res.data.totalResults;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.vuecal__cell-events-count {
  width: 18px;
  height: 2px;
  color: transparent;
}

.vuecal__event {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.35);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid rgba(241, 65, 108, 0.8);

  &.down-paid {
    border-left: 5px solid rgba(26, 227, 19, 0.8);
  }
  &.info {
    background-color: rgba(34, 55, 240, 0.35);
  }
  &.danger {
    background-color: rgba(241, 65, 108, 0.35);
  }
  &.success {
    background-color: rgba(80, 205, 136, 0.35);
  }
}

/* Green-theme. */
.vuecal__menu,
.vuecal__cell-events-count {
  background-color: #111;
  color: #eff2f5;
}
.vuecal__title-bar {
  background-color: #eaebf5;
}
.vuecal__cell--today,
.vuecal__cell--current {
  background-color: rgba(225, 225, 225, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
  background-color: rgba(225, 225, 225, 0.4);
}
.vuecal__cell--selected:before {
  border-color: rgba(17, 17, 17, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
  background-color: rgba(255, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
  background-color: rgba(172, 172, 172, 0.25);
}
</style>
