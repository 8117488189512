<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content-sm"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title"> Calendar Export </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Calendar link</h5>
      <div class="input-group">
        <input
          class="form-control"
          type="text"
          v-model="calendarUrl"
          disabled
        />
        <button type="button" class="btn btn-info" @click="copyUrl">
          <i class="bi-link-45deg"></i>Copy
        </button>
      </div>
      <p class="text-muted mt-4">
        Copy this link and paste it in your calendar client to synchronize them.
      </p>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', operation)"
        >Confirm</a
      > -->
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import JwtService from "@/core/services/JwtService";
import { useToast } from "vue-toastification";

export default {
  emits: ["cancel"],
  data() {
    return {
        toast: useToast(),
    };
  },
  computed: {
    calendarUrl() {
      return `${process.env.VUE_APP_API_URL}/clinics/ics/${
        JwtService.getUser().clinic.token
      }`;
    },
  },
  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.calendarUrl).then(
        () => {
          this.toast.success("Link copied with success!");
        },
        function (err) {
          this.toast.error("Error: couldn't copy link, try again.");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
  overflow-y: visible !important;
}
</style>